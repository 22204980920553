import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ModalImage from "react-modal-image";
import useS3 from "../../hooks/useS3";
import { api } from "../../services/api";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const useStyles = makeStyles(() => ({
  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  downloadButton: {
    position: "absolute",
    bottom: 10,
    right: 10,
    backgroundColor: "#D9F1FF", 
    color: "#007bff", 
    border: "none",
    borderRadius: "50%", 
    width: 40,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)", 
    cursor: "pointer",
    zIndex: 10,
  },
  modalWrapper: {
    position: "relative",
    display: "inline-block",
  },
  hideDownloadButton: {
    "& .modal-image-download-button": {
      display: "none !important", 
    },
  },
}));

const ModalImageCors = ({ imageUrl }) => {
  const classes = useStyles();
  const { blobUrl } = useS3(imageUrl);

  const handleDownload = async () => {

    try {
      const response = await api.post(`/messages/download-temp`, { imageUrl }, {
        responseType: "blob",
      });

      const disposition = response.headers["content-disposition"];
      const fileName = disposition
        ? disposition.split("filename=")[1].replace(/"/g, "")
        : imageUrl.split("/").pop();

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <div className={`${classes.modalWrapper} ${classes.hideDownloadButton}`}>
      <button
        className={classes.downloadButton}
        onClick={() => handleDownload(imageUrl)}
      >
        <FontAwesomeIcon icon={faDownload} size="lg" />
      </button>
      <ModalImage
        className={classes.messageMedia}
        hideDownload={true}
        hideZoom={true}
        smallSrcSet={blobUrl}
        medium={blobUrl}
        large={blobUrl}
        alt="image"
      />
    </div>
  );
};

export default ModalImageCors;
