import { makeStyles } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import useS3 from "../../hooks/useS3";
import { api } from "../../services/api";

const useStyles = makeStyles(() => ({
  imageStyle: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderRadius: 8,
  },
  downloadButton: {
    marginTop: 8,
    padding: "8px 16px",
    border: "none",
    borderRadius: 4,
    backgroundColor: "#007bff",
    color: "white",
    cursor: "pointer",
  },
}));

const ImageComponent = ({ imageUrl, idGraf, fileName }) => {
  const [imageSrc, setImageSrc] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const { blobUrl } = useS3(imageUrl);

  useEffect(() => {
    if (blobUrl) {
      setImageSrc(blobUrl);
    }
  }, [blobUrl]);

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const response = await api.post(`/messages/download-temp`, { imageUrl }, {
        responseType: "blob", // Recebe como blob diretamente
      });

      
      const disposition = response.headers["content-disposition"];
      const fileName = disposition
        ? disposition.split("filename=")[1].replace(/"/g, "") 
        : imageUrl.split("/").pop(); 

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName); 
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url); 
    } catch (error) {
      console.error("Error downloading file:", error);
      setError("Failed to download the file");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {error && <p className={classes.error}>{error}</p>}
      <img src={imageSrc || 'default-placeholder.png'} alt="Fetched content" className={classes.imageStyle} />
      <button
        onClick={handleDownload}
        className={classes.downloadButton}
        disabled={isLoading}
      >
        {isLoading ? "Downloading..." : "Download Image"}
      </button>
    </div>
  );
};

export default ImageComponent;
