import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";


function cleanMessageVCard(message) {
    if (!message) return <br />;
    if (message === "Imagem") {
        return (
            <span>
                <FontAwesomeIcon icon={faImage} style={{ marginRight: "5px" }} />
                Imagem
            </span>
        );
    }
    if (message.includes("BEGIN:VCARD\nVERSION:3.0\nN:;")) {
        return "Contato";
    } else {
        return message.length > 45 ? `${message.substring(0, 35)}...` : message;
    }
}

export { cleanMessageVCard };
