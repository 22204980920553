import { useState, useEffect } from "react";
import { api } from "../../services/api";

const useS3 = (mediaURL, type = 'other') => {
  const [blobUrl, setBlobUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchMedia = async () => {
      if (!mediaURL) return;
      setLoading(true);
      try {
        const path = mediaURL.split('/');
        const { data } = await api.get(`/messages/download-file/${path[2]}/${path[3]}`);
        setBlobUrl(data);
  
        if (type === 'video') {
          const newFileName = path[3].replace('.mp4', '.jpg');
          const thumbnailPath = `/messages/download-file/${path[2]}/thumbnail_${newFileName}`;
          const { data: thumbnailData } = await api.get(thumbnailPath);
          setThumbnailUrl(thumbnailData);
        }
      } catch (error) {
        console.error('Failed to fetch media:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchMedia();
  }, [mediaURL, type]);
  

  return { blobUrl, thumbnailUrl, loading };
};

export default useS3;
